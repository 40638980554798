(function () {
	'use strict';
	angular

		.module('header')
		.controller('HeaderCtrl', [
			'$scope',
			'$rootScope',
			'$q',
			'$timeout',
			'hash',
			'utilities',
			'seedcodeCalendar',
			'manageResources',
			'manageGridTimes',
			'manageConfig',
			'manageSettings',
			'manageNotifications',
			'crypt',
			'environment',
			'filemakerJS',
			'shares',
			'manageBreakout',
			'manageView',
			HeaderCtrl,
		]);

	function HeaderCtrl(
		$scope,
		$rootScope,
		$q,
		$timeout,
		hash,
		utilities,
		seedcodeCalendar,
		manageResources,
		manageGridTimes,
		manageConfig,
		manageSettings,
		manageNotifications,
		crypt,
		environment,
		filemakerJS,
		shares,
		manageBreakout,
		manageView
	) {
		var preview;
		var previewElement;
		//Load config from our model and watch for future updates
		$scope.userId = seedcodeCalendar.get('config').userID;
		$scope.calendar = {};
		$scope.bookmarks = {};
		$scope.calendar.config = seedcodeCalendar.get('config');

		$scope.calendar.view = seedcodeCalendar.get('view');

		$scope.calendar.minDate = minDate();

		$scope.header = {};
		$scope.header.panel = {};
		$scope.showMenuMatrix = {};

		$scope.$on('view', function () {
			$scope.$evalAsync(function () {
				$scope.calendar.view = seedcodeCalendar.get('view');
				$scope.calendar.minDate = minDate();
			});
		});

		$scope.$on('resize', function () {
			initMenu();
		});

		//Initialize notifications object
		$scope.notifications = {};

		//Add using static data to notifications
		$scope.notifications.useStaticData = _CONFIG.DBK_USE_STATIC_DATA;

		//Listen for loading events
		$scope.$on('loading-events', function (event, data) {
			loadingEvents(data);
		});

		//Listen for notification updates
		$scope.$on('update-notifications', function (update, data) {
			updateNotifications();
		});

		$scope.$on('activation', function (hash) {
			updateNotifications();
		});

		$scope.isFileMakerWebViewer = utilities.getDBKPlatform() === 'dbkfm';
		// $scope.purchaseURL = function() {
		// 	return hash.getPurchaseURL($scope.isFileMakerWebViewer);
		// };
		$scope.webviewerSharingInactive =
			$scope.isFileMakerWebViewer &&
			!$scope.calendar.config.linkedUserToken;

		$scope.platform = utilities.getDBKPlatform();
		$scope.googleEnabled = sourceTypeEnabled(3);
		$scope.salesforceEnabled = sourceTypeEnabled(10);

		$scope.sourceTypeEnabled = sourceTypeEnabled;

		$scope.appendRemoteNotifications = appendRemoteNotifications;

		$scope.gotoPurchase = function () {
			hash.gotoPurchase(function () {
				$scope.fetchingActivation = true;

				manageSettings.getActivation(processActivation);

				function processActivation(activationObj) {
					crypt.verifyHash(activationObj, null, null, callback, null);
				}

				function callback() {
					$scope.$evalAsync(function () {
						$scope.fetchingActivation = false;
						updateNotifications();
					});
				}
			});
		};

		//Set initial state of loading events
		loadingEvents(seedcodeCalendar.get('loading-events'));

		//Set initial state of notifications
		updateNotifications();

		//Set initial state if sharing and bookmarks are Allowed
		isShareEnabled();
		bookmarksEnabled();

		initMenu();

		function initMenu() {
			var windowWidth = window.innerWidth;
			if (windowWidth < 1250) {
				$scope.verticalMenu = true;
			} else {
				$scope.verticalMenu = false;
			}
		}

		function minDate() {
			if ($scope.calendar.config.minDate && $scope.calendar.view) {
				return $scope.calendar.view.intervalStart.isSameOrBefore(
					moment($scope.calendar.config.minDate)
				);
			}
		}

		function sourceTypeEnabled(sourceTypeID) {
			var sources = seedcodeCalendar.get('sources');
			for (var i = 0; i < sources.length; i++) {
				if (sources[i].sourceTypeID === sourceTypeID) {
					return sources[i].sourceTypeEnabled;
				}
			}
		}

		function loadingEvents(loading) {
			$scope.$evalAsync(function () {
				$scope.loadingEvents = loading;
			});
		}

		function updateNotifications() {
			var config = seedcodeCalendar.get('config');
			//Get our remote notifications - In a timeout so we keep this as async as possible and delay so it doesn't delay initial load of calendar at all
			if (
				config.showRemoteNotifications === 'everyone' ||
				(config.showRemoteNotifications === 'admin' && config.admin)
			) {
				window.setTimeout(function () {
					checkRemoteNotifications();
				}, 2000);
			}

			//Get any licesne related notifications
			checkLicenseNotifications();
		}

		function checkRemoteNotifications() {
			var now = moment(moment().format('YYYY-MM-DD'));
			if (
				$scope.notifications.remote &&
				now.isSame($scope.notifications.remote.dateUpdated)
			) {
				return;
			}

			manageNotifications.getRemoteNotifications(null, function (result) {
				$scope.notifications.remote = result;
				if (result) {
					$scope.notifications.show = true;
					$scope.notifications.content = result;
				}

				notificationsReceived();
				getNotificationsRead(result);
			});
		}

		function appendRemoteNotifications() {
			if (!$scope.notifications || !$scope.notifications.remote) {
				checkRemoteNotifications();
				return;
			}

			var moreLoadedCount = $scope.notifications.moreLoaded
				? $scope.notifications.moreLoaded + 1
				: 1;

			$scope.notifications.loading = true;
			manageNotifications.getRemoteNotifications(
				moreLoadedCount,
				function (result) {
					var currentNotifications = $scope.notifications.remote.data;
					var newNotifications = result.data;
					$scope.$evalAsync(function () {
						for (var i = 0; i < newNotifications.length; i++) {
							currentNotifications.push(newNotifications[i]);
						}
						$scope.$broadcast('notificationsUpdated', true);
						$scope.notifications.loading = null;
						$scope.notifications.moreLoaded = moreLoadedCount;
					});
				}
			);
		}

		function checkLicenseNotifications() {
			var now = moment(moment().format('YYYY-MM-DD'));
			var expiresData = manageNotifications.expiresNotification(now);
			//Do not evaluate if the day hasn't changed since last check
			if (
				$scope.notifications.license &&
				now.isSame($scope.notifications.license.dateUpdated, 'day') &&
				expiresData.type === $scope.notifications.license.type &&
				expiresData.orderNumber ===
					$scope.notifications.license.orderNumber
			) {
				return;
			}

			// Should we show an alert icon in the notification menu icon
			expiresData.showAlert = expiresData.expiresAlert;

			if (
				!$scope.notifications.license ||
				expiresData.show !== $scope.notifications.license.show ||
				expiresData.orderNumber !==
					$scope.notifications.license.orderNumber
			) {
				$scope.$evalAsync(function () {
					$scope.notifications.license = expiresData;
				});
			}
		}

		function notificationsReceived() {
			$scope.$broadcast('notificationsUpdated', true);
		}

		function getNotificationsRead(notificationData) {
			manageNotifications.getNotificationsRead(function (result) {
				processReadNotifications(result);
			});
		}

		function processReadNotifications(result) {
			var notificationID;
			var hasUnread;
			var showMessage;
			var lastReadID =
				!result || !result.lastReadID ? 0 : Number(result.lastReadID);
			if (
				!$scope.notifications.remote ||
				!$scope.notifications.remote.data
			) {
				return;
			}

			for (var i = 0; i < $scope.notifications.remote.data.length; i++) {
				notificationID = Number($scope.notifications.remote.data[i].id);
				if (lastReadID < notificationID) {
					$scope.notifications.remote.data[i].unread = true;
					$scope.notifications.remote.data[i].unread = true;
					hasUnread = true;
					if (
						!showMessage &&
						$scope.notifications.remote.data[i].showMessage
					) {
						showMessage = $scope.notifications.remote.data[i];
					}
				} else {
					$scope.notifications.remote.data[i].unread = false;
				}
			}

			$scope.$evalAsync(function () {
				var infoButtonText;
				var infoButtonFunction;
				var messageContent;

				if (hasUnread) {
					$scope.notifications.remote.showUnread = true;
				} else {
					$scope.notifications.remote.showUnread = false;
				}
				if (showMessage) {
					// Set a show message var in the scope that we can check against when marking as read
					$scope.notifications.remote.showMessage = showMessage;

					infoButtonText = showMessage.buttonTextPrimary
						? showMessage.buttonTextPrimary
						: '';
					infoButtonFunction = showMessage.buttonURLPrimary
						? function () {
								clearShowMessage();
								utilities.help(
									showMessage.buttonURLPrimary,
									showMessage.buttonURLPrimary,
									true
								);
							}
						: '';
					messageContent = '';
					if (showMessage.subhead) {
						messageContent +=
							'<p class="text-muted">' +
							showMessage.subhead +
							'</p>';
					}
					messageContent += '<p>' + showMessage.content + '</p>';

					// Display dialog containing show message content
					utilities.showModal(
						showMessage.title,
						messageContent,
						infoButtonText,
						infoButtonFunction,
						'Dismiss',
						clearShowMessage,
						null,
						null,
						false, // Is error message
						null,
						true // HTML description
					);
				}
			});
		}

		function clearShowMessage() {
			setNotificationsRead();
			$scope.notifications.remote.showMessage = null;
		}

		function setNotificationsRead() {
			if (!$scope.notifications?.remote) {
				return;
			}
			var lastReadID = $scope.notifications.remote.showMessage
				? $scope.notifications.remote.showMessage.id
				: $scope.notifications.remote.data[0].id;

			if (
				$scope.notifications.remote &&
				$scope.notifications.remote.data &&
				$scope.notifications.remote.showUnread
			) {
				manageNotifications.setNotificationsRead(
					$scope.notifications.remote.data,
					lastReadID
				);
				processReadNotifications({lastReadID: lastReadID});
			}
		}

		$scope.showNotifications = function () {
			return (
				($scope.notifications.remote &&
					$scope.notifications.remote.show) ||
				($scope.notifications.license &&
					$scope.notifications.license.show)
			);
		};
		$scope.notificationsMenuClicked = function () {
			$scope.loadNotificationMessages = true;
			notificationsReceived();
			$rootScope.$broadcast('menu-notifications-shown', true);
		};

		$scope.notificationMenuClosed = function () {
			$rootScope.$broadcast('menu-notifications-shown', false);
			setNotificationsRead();
		};

		$scope.updateSetting = manageConfig.updateSetting;

		$scope.applyLocalSetting = function (
			setting,
			preventFetching,
			preventInit
		) {
			var delay = environment.isMobileDevice ? 400 : 0;
			$timeout(function () {
				manageConfig.applyLocalSetting(
					setting,
					preventFetching,
					preventInit
				);
			}, delay);
		};

		$scope.applyHorizonSliderValue = function () {
			manageConfig.applyLocalSetting('horizonSlider');
		};

		$scope.getSettingsOptions = manageConfig.getSettingsOptions;

		$scope.confirmUpdateShare = confirmUpdateShare;
		$scope.manageShares = manageShares;
		$scope.previewShare = previewShare;
		$scope.destroyPreview = destroyPreview;
		$scope.getBookmarkList = getBookmarkList;
		$scope.toggleFavorite = toggleFavorite;

		$scope.breakoutMore = breakoutMore;

		$scope.updateSharePrivilege = function (value) {
			manageConfig.updateSetting('sharePrivileges', value);
			isShareEnabled();
			bookmarksEnabled();
		};

		/* Change View */
		$scope.changeView = manageView.changeView;

		$scope.changeHorizonView = manageView.changeHorizonView;

		$scope.preventClick = function (e, prevent) {
			if (prevent) {
				e.preventDefault();
				e.stopPropagation();
				return false;
			}
			return true;
		};

		$scope.datePicker = datePicker;

		$scope.navigate = function ($event, navDirection) {
			// Next or Prev
			var altClick = $event.shiftKey;
			seedcodeCalendar
				.get('element')
				.fullCalendar(navDirection, altClick);
			if (
				utilities.getDBKPlatform() &&
				utilities.getDBKPlatform() === 'dbkfmjs'
			) {
				filemakerJS.loadFileMakerJSConfig();
			}
		};

		$scope.nextResource = function ($event) {
			var altClick = $event.shiftKey;
			manageResources.next(altClick);
		};

		$scope.previousResource = function ($event) {
			var altClick = $event.shiftKey;
			manageResources.previous(altClick);
		};

		$scope.resetResource = function () {
			manageResources.reset();
		};

		$scope.previousResourcePossible = function () {
			return manageResources.previousPossible();
		};

		$scope.nextResourcePossible = function () {
			return manageResources.nextPossible();
		};

		$scope.resourcePageCount = function () {
			return manageResources.pageCount();
		};

		$scope.resourcePage = function () {
			return manageResources.currentPage();
		};

		$scope.nextTime = function ($event) {
			var altClick = $event.shiftKey;
			manageGridTimes.next(altClick);
		};

		$scope.previousTime = function ($event) {
			var altClick = $event.shiftKey;
			manageGridTimes.previous(altClick);
		};

		$scope.focusTime = function () {
			var config = seedcodeCalendar.get('config');
			var focusTime = manageGridTimes.getStartTime();
			if (!config) {
				return;
			}
			var date = config.defaultDate
				? moment.utc(config.defaultDate)
				: moment.utc(moment().format('YYYY-MM-DD'));
			return date
				.add(moment.duration(focusTime))
				.format(config.timeFormat);
		};

		$scope.resetTime = function () {
			manageGridTimes.reset();
		};

		$scope.previousTimePossible = function () {
			return manageGridTimes.previousPossible();
		};

		$scope.nextTimePossible = function () {
			return manageGridTimes.nextPossible();
		};
		$scope.refreshEvents = function () {
			var schedules = seedcodeCalendar.get('schedules');
			var promises = [];

			var deferredRequest = $q.defer();

			for (var i = 0; i < schedules.length; i++) {
				if (schedules[i].source && schedules[i].source.refresh) {
					promises.push(deferredRequest.promise);
					schedules[i].source.refresh(schedules[i], promiseCallback);
				}
			}

			if (!promises.length) {
				deferredRequest.resolve(true);
			}

			$q.all(promises).then(
				function (result) {
					refresh(result);
				},
				function (error) {
					refresh(error);
				}
			);

			function promiseCallback(result) {
				deferredRequest.resolve(result);
			}
			function refresh() {
				//second param is if this is a refresh, should be set to true to specify refresh
				seedcodeCalendar
					.get('element')
					.fullCalendar('refetchEvents', true);
			}
		};

		$scope.resourceCountSelector = function () {
			const resources = seedcodeCalendar.get('resources');
			if (!resources) {
				return 0;
			}
			const filteredResources = resources.filter(function (value) {
				return !value.isFolder;
			});
			const count = [];
			for (let i = 1; i <= filteredResources.length; i++) {
				count.push(i);
			}
			return count;
		};

		$scope.applyResourceCount = function () {
			manageResources.init(
				Number($scope.calendar.config.resourceColumns)
			);
			manageResources.reset();
		};

		/** @type{(min: number, max: number) => Array<number>} */
		$scope.daysCount = function (min, max) {
			const numbers = [
				1, 2, 3, 4, 5, 6, 7, 14, 21, 30, 35, 49, 98, 161, 364,
			];
			const output = [];
			for (let i = 0; i < numbers.length; i++) {
				if (min && min > numbers[i]) {
					continue;
				}
				if (max && max < numbers[i]) {
					break;
				}
				output.push(numbers[i]);
			}
			return output;
		};

		$scope.help = function (page, pagesf, fullURL, pageShare) {
			utilities.help(page, pagesf, fullURL, pageShare);
		};

		$scope.signUp = function () {
			var url = utilities.getBaseURL() + 'sign-up';
			utilities.help(url, url, true);
		};

		$scope.isSalesforce = fbk.isSalesforce();

		$scope.matchArrayItem = matchArrayItem;

		$scope.configClick = function (e, preventClose) {
			if (preventClose) {
				e.stopPropagation();
			}
			return false;
		};

		function matchArrayItem(value, array) {
			if (!array) {
				return;
			}
			for (var i = 0; i < array.length; i++) {
				if (value === array[i]) {
					return true;
				}
			}
		}

		function datePicker() {
			var datePicker = {};
			var popover = {
				id: 'datePicker',
				controller: 'MinicalsCtrl',
				container: $('body'),
				type: 'modal', // modal or popover
				width: 380,
				// positionX: e.pageX,
				// positionY: e.pageY,
				data: datePicker,
				dataTitle: 'datePicker',
				destroy: true,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
			};
			utilities.popover(
				popover,
				'<div ng-include="\'app/mobile/header/date-picker.html\'"></div>'
			);
		}

		function isShareEnabled() {
			var config = seedcodeCalendar.get('config');
			var privilege = config.sharePrivileges;
			if (
				!config.preventSharing &&
				((privilege === 'admin' && config.admin) ||
					privilege === 'everyone')
			) {
				$scope.isShareEnabled = true;
				return true;
			} else {
				$scope.isShareEnabled = false;
				return false;
			}
		}

		function bookmarksEnabled() {
			var config = seedcodeCalendar.get('config');
			var privilege = config.sharePrivileges;

			$scope.bookmarksEnabled = true;
			return true;
		}

		function confirmUpdateShare(share) {
			const popover = {
				id: 'updateShare',
				controller: 'SharesCtrl',
				container: $('body'),
				type: 'modal', // modal or popover
				width: 600,
				// positionX: e.pageX,
				// positionY: e.pageY,
				data: share,
				dataTitle: 'share',
				destroy: true,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
			};
			utilities.popover(
				popover,
				'<div ng-include="\'app/options/shares-update-dialog.html\'"></div>'
			);
		}

		function manageShares($event, create, bookmark, openToView) {
			if (!bookmarksEnabled()) {
				$event.stopPropagation();
				return;
			}
			var popover;
			var share = bookmark
				? bookmark
				: {
						create: create,
						secondPanel: create ? true : false,
					};

			if (openToView && share.id) {
				shares.update(
					share.id,
					function (updatedShare, error) {
						if (error) {
							utilities.showModal(
								error.title || 'Unable to visit share',
								error.content,
								error.cancelButtonText || 'ok'
							);
						}
					},
					null,
					true
				);
			} else {
				popover = {
					id: 'manageShares',
					controller: 'SharesCtrl',
					container: $('body'),
					type: 'modal', // modal or popover
					width: 600,
					// positionX: e.pageX,
					// positionY: e.pageY,
					data: share,
					dataTitle: 'share',
					destroy: true,
					onShow: '',
					onShown: '',
					onHide: '',
					onHidden: '',
					show: true,
				};
				utilities.popover(
					popover,
					'<div ng-include="\'app/options/shares.html\'"></div>'
				);
			}
		}

		function getBookmarkList() {
			var getUser = seedcodeCalendar.get('userPromises').getUser();
			$scope.loadingRecentBookmarks = true;
			getUser.then(function (result) {
				$scope.userId = result ? result.id : null;
				shares.getUserShareList(function (result) {
					var platform = utilities.getDBKPlatform();
					var shareList = [];
					for (var property in result) {
						if (
							utilities.basePlatformMatch(
								platform,
								result[property].platform
							)
						) {
							shareList.push(processShare(result[property]));
						}
					}
					$scope.$evalAsync(function () {
						$scope.bookmarks.list = shareList;
						updateBookmarkList();
						$scope.loadingRecentBookmarks = false;
					});
				});
			});
		}

		function updateBookmarkList() {
			$scope.bookmarks.list.sort(favoriteSort);

			function favoriteSort(a, b) {
				var aFavoriteCreated = a.favorite[$scope.userId]
					? a.favorite[$scope.userId].created
					: null;
				var bFavoriteCreated = b.favorite[$scope.userId]
					? b.favorite[$scope.userId].created
					: null;
				if (aFavoriteCreated && bFavoriteCreated) {
					if (aFavoriteCreated > bFavoriteCreated) {
						return -1;
					} else {
						return 1;
					}
				} else if (aFavoriteCreated && !bFavoriteCreated) {
					return -1;
				} else if (bFavoriteCreated && !aFavoriteCreated) {
					return 1;
				} else {
					if (a.created > b.created) {
						return -1;
					} else {
						return 1;
					}
				}
			}
		}

		function processShare(share) {
			share.url = shares.getShareURL(
				share.id,
				!share.shareWith || share.shareWith === 'public'
			);
			share.shareWithHuman =
				share.shareWith === 'self'
					? 'Just Me'
					: share.shareWith === 'group'
						? 'My Group'
						: 'Public';
			share.favorite = share.favorite || {};
			share.favoriteSelected = !!share.favorite[$scope.userId];
			return share;
		}

		function previewShare($event, share) {
			if (!share) {
				$event.stopPropagation();
				return;
			}

			var data = {share: share};

			destroyPreview($event);
			// Add focus from share in list
			$scope.focusedShare = share.id;

			preview = {
				id: 'previewShare',
				controller: 'SharesPreviewCtrl',
				container: $('body'),
				type: 'popover',
				target: $event.currentTarget,
				data: data,
				dataTitle: 'data',
				destroy: true,
				direction: 'left',
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				width: 190,
				anchorTop: true,
				useTargetOffset: true,
				show: true,
				class: 'popover-menu bookmark-preview',
			};

			previewElement = utilities.popover(
				preview,
				'<div ng-include="\'app/options/shares-preview.html\'"></div>'
			).element;
		}

		function destroyPreview($event) {
			var enteredPreview =
				$event.type === 'mouseleave' &&
				$event.relatedTarget &&
				previewElement &&
				previewElement[0] &&
				(previewElement[0] === $event.relatedTarget ||
					previewElement[0].contains($event.relatedTarget));
			if (preview && !enteredPreview) {
				preview.show = false;
				// Remove focus from share in list
				$scope.focusedShare = null;
			}
		}

		function toggleFavorite(bookmark) {
			if (!bookmark.favorite) {
				bookmark.favorite = {};
			}
			// var favorite = bookmark.favorite || {};
			if (bookmark.favoriteSelected) {
				delete bookmark.favorite[$scope.userId];
			} else {
				bookmark.favorite[$scope.userId] = {
					created: moment().valueOf(),
				};
			}

			bookmark.favoriteSelected = !!bookmark.favorite[$scope.userId];

			shares.updateShareProperty(
				bookmark,
				'favorite',
				bookmark.favorite,
				callback
			);

			function callback(data) {
				$scope.$evalAsync(function () {
					bookmark.favorite = data || bookmark.favorite;
				});
			}
		}

		function breakoutMore() {
			var breakout = {};
			var popover = {
				id: 'breakoutFields',
				controller: 'BreakoutCtrl',
				container: $('body'),
				type: 'modal', // modal or popover
				width: 300,
				// positionX: e.pageX,
				// positionY: e.pageY,
				data: breakout,
				dataTitle: 'breakout',
				destroy: true,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
			};
			utilities.popover(
				popover,
				'<div ng-include="\'app/breakout/breakout-list-modal.html\'"></div>'
			);
		}
	}
})();
